import "@styles/index.scss"
import { graphql, useStaticQuery } from "gatsby"
import { ErrorBoundary } from "react-error-boundary"
import ErrorFallback from "@components/common/ErrorFallback"
import loadable from "@loadable/component"
const Footer = loadable(() => import("./components/Footer"))
const Header = loadable(() => import("./components/Header"))
import React from "react"

const Layout = ({ children }) => {
  const {
    allShopifyCollection: { edges },
    allSanityCollection,
  } = useStaticQuery(query)
  const collections = edges.map((edge) => edge.node)
  const sanityCollections = allSanityCollection.edges.map((edge) => edge.node)
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Header sanityCollections={sanityCollections} collections={collections} />
      <main>{children}</main>
      <Footer collections={collections} />
    </ErrorBoundary>
  )
}

const query = graphql`
  query {
    allShopifyCollection(
      filter: {
        handle: {
          in: [
            "retail-brands"
            "covid-19-testing"
            "drug-of-abuse-testing"
            "government-services"
          ]
        }
      }
    ) {
      edges {
        node {
          title
          handle
          products {
            title
            handle
          }
        }
      }
    }
    allSanityCollection(
      filter: {
        handle: {
          current: {
            in: [
              "retail-brands"
              "covid-19-testing"
              "drug-of-abuse-testing"
              "government-services"
            ]
          }
        }
      }
    ) {
      edges {
        node {
          id
          handle {
            current
          }
          faqs {
            question
          }
          services {
            href
            text
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`

export default Layout
