const React = require("react")
const Layout = require("./src/components/Layout").default
const { ShopProvider } = require("./src/contexts/shop")

exports.wrapPageElement = ({ element, props }) => {
  return (
    <ShopProvider>
      <Layout {...props}>{element}</Layout>
    </ShopProvider>
  )
}
