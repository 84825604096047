// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-knowledge-tsx": () => import("./../../../src/pages/knowledge.tsx" /* webpackChunkName: "component---src-pages-knowledge-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-templates-collection-collection-tsx": () => import("./../../../src/templates/Collection/Collection.tsx" /* webpackChunkName: "component---src-templates-collection-collection-tsx" */),
  "component---src-templates-landing-landing-page-tsx": () => import("./../../../src/templates/Landing/LandingPage.tsx" /* webpackChunkName: "component---src-templates-landing-landing-page-tsx" */),
  "component---src-templates-product-product-tsx": () => import("./../../../src/templates/Product/Product.tsx" /* webpackChunkName: "component---src-templates-product-product-tsx" */),
  "component---src-templates-resource-resource-tsx": () => import("./../../../src/templates/Resource/Resource.tsx" /* webpackChunkName: "component---src-templates-resource-resource-tsx" */),
  "component---src-templates-resources-resources-tsx": () => import("./../../../src/templates/Resources/Resources.tsx" /* webpackChunkName: "component---src-templates-resources-resources-tsx" */),
  "component---src-templates-simple-simple-page-tsx": () => import("./../../../src/templates/Simple/SimplePage.tsx" /* webpackChunkName: "component---src-templates-simple-simple-page-tsx" */)
}

