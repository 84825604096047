/* eslint-disable no-console */
import { IShopCheckout, IShopContext } from "@utils/globalTypes"
import React, { createContext, useEffect, useState } from "react"

import Client from "shopify-buy"

const client = Client.buildClient({
  domain: `${process.env.SHOP_NAME}.myshopify.com`,
  storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN,
})

const defaultValues: IShopContext = {
  isLoading: false,
  isCartOpen: false,
  quantity: 0,
  checkout: {
    id: "",
    lineItemCount: 0,
    subtotalPrice: "",
    completedAt: null,
    checkoutUrl: "",
    lineItems: [],
  },
  coupon: "",
  toggleCartOpen: () => {},
  onCartClose: () => {},
  client,
}

const isBrowser = typeof window !== "undefined"

export const ShopContext = createContext<IShopContext>(defaultValues)

export const ShopProvider = ({ children }) => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isCartOpen, setCartOpen] = useState<boolean>(false)
  const [checkout, setCheckout] = useState<IShopCheckout>(
    defaultValues.checkout
  )
  const [coupon, setCoupon] = useState<string>("")

  const toggleCartOpen = () => setCartOpen(!isCartOpen)
  const onCartClose = () => setCartOpen(false)
  const onCouponChange = (value: string) => setCoupon(value)

  const addProductToCart = async (variantId: string, quantity: number) => {
    try {
      console.log("ADD PRODUCT TO CARD")
      setLoading(true)
      const lineItemsToAdd = [
        {
          variantId,
          quantity,
        },
      ]
      const newCheckout = await client.checkout.addLineItems(
        checkout.id,
        lineItemsToAdd
      )
      console.log(newCheckout)
      setCheckout(newCheckout)
      toggleCartOpen()
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  const updateQuantity = async (id: string, quantity: number) => {
    try {
      setLoading(true)
      const lineItemsToUpdate = [
        {
          id,
          quantity,
        },
      ]
      console.log(checkout.id, lineItemsToUpdate)
      const newCheckout = await client.checkout.updateLineItems(
        checkout.id,
        lineItemsToUpdate
      )
      setCheckout(newCheckout as IShopCheckout)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  const removeProductFromCart = async (id: string) => {
    try {
      setLoading(true)
      const lineItemIdsToRemove = [id]
      const newCheckout = await client.checkout.removeLineItems(
        checkout.id,
        lineItemIdsToRemove
      )
      setCheckout(newCheckout)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  const checkCoupon = async (coupon: string) => {
    try {
      setLoading(true)
      const newCheckout = await client.checkout.addDiscount(checkout.id, coupon)
      console.log(newCheckout)
      setCheckout(newCheckout)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  const removeCoupon = async () => {
    try {
      setLoading(true)
      const newCheckout = await client.checkout.removeDiscount(checkout.id)
      setCheckout(newCheckout)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  const quantity = checkout?.lineItems?.reduce(
    (total, item) => total + item.quantity,
    0
  )

  useEffect(() => {
    const createNewCheckout = async () => {
      try {
        const newCheckout = await client.checkout.create()
        if (isBrowser) {
          localStorage.setItem("checkout_id", newCheckout.id.toString())
        }
        return newCheckout
      } catch (e) {
        console.error(e)
      }
    }

    const initializeCheckout = async () => {
      try {
        const currentCheckoutId = isBrowser
          ? localStorage.getItem("checkout_id")
          : null
        let newCheckout = null
        if (currentCheckoutId) {
          newCheckout = await client.checkout.fetch(currentCheckoutId)
          if (!newCheckout || newCheckout.completedAt) {
            newCheckout = await createNewCheckout()
          }
        } else {
          newCheckout = await createNewCheckout()
        }
        setCheckout(newCheckout)
      } catch (e) {
        console.error(e)
      }
    }
    initializeCheckout()
  }, [])
  return (
    <ShopContext.Provider
      value={{
        ...defaultValues,
        isLoading,
        isCartOpen,
        quantity,
        checkout,
        coupon,
        toggleCartOpen,
        onCartClose,
        addProductToCart,
        updateQuantity,
        removeProductFromCart,
        onCouponChange,
        checkCoupon,
        removeCoupon,
      }}
    >
      {children}
    </ShopContext.Provider>
  )
}
