import React, { FC } from "react"
import { Container, Row, Col } from "react-bootstrap"
import * as s from "./ErrorFallback.module.scss"
import { Link } from "gatsby"

type Props = {
  error?: Error
  componentStack?: string
}

const ErrorFallback: FC<Props> = ({ error }) => {
  return (
    <Container className={s.container}>
      <Row className="justify-content-center">
        <Col xs={12}>
          <h2>Something went wrong</h2>
        </Col>
        <Col xs={12}>
          <h6>{error.message}</h6>
        </Col>
        <Col xs={12}>
          <Link to="/" className={s.goHome}>
            Go Home
          </Link>
        </Col>
      </Row>
    </Container>
  )
}

export default ErrorFallback
